<template>
	<main>
    <div class="section1400">
      <div id="box">
        <img src="../../../assets/img/nonochun.png">
        <h4 class="mt-4">페이지를 찾을 수 없습니다.</h4>
      </div>
    </div>
	</main>
</template>
<style scoped>
main {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
<script setup>
</script>